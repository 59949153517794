import {
  Autosuggest,
  Alert,
  Box,
  BreadcrumbGroup,
  Button,
  Container,
  ColumnLayout,
  DatePicker,
  Form,
  FormField,
  Header,
  Input,
  Link,
  Multiselect,
  Select,
  SpaceBetween,
  Spinner,
  Textarea as TextArea,
  Tiles,
}
from "@awsui/components-react";
import { areas, categories, levels, users } from "./Enumerations";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import isURL from 'validator/lib/isURL';
import Confirm from './Confirm';
import { getItems, saveItem, deleteItem, searchUser } from "./API";
import {
  AUTHOR_IMAGE,
  AUTHOR_NAME
}
from './Common';

// eslint-disable-next-line
export default () => {

  const { id } = useParams();
  const [editing, setEditing] = useState(false);
  const [message, setMessage] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState(null);
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [authorValue, setAuthorValue] = useState("");
  const [userList, setUserList] = useState([{label: "Test User", value: "tuser"}])
  const [searchingUsers, setSearchingUsers] = useState(false)
  const existing = id !== 'create';
  const alias = sessionStorage.getItem("alias");
  const history = useHistory();

  const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box margin={{ bottom: 'xxxs' }} color="text-label">
        {label}
      </Box>
      <div>{children}</div>
    </div>
  );

  useEffect(() => {
    if (!existing) {
      // set defaults if any
      setItem({
        status: "Proposed",
        createdBy: alias,
        authors: alias
        // id left intentionally blank for create case
      });
      setEditing(true);
      setSaving(false);
      setLoading(false);
    }
    else {
      // fetch by id when possible
      setLoading(true);
      //NOTE: api not yet supported: getItems({id:id}).then(
      getItems().then(
        (result) => {
          //console.log(result);
          for (let i in result) {
            if (result[i].id === id) {
              setItem(result[i]);
              break;
            }
          }
          setLoading(false);
        },
        (error) => {
          setError(error.message);
          console.log(error);
          setLoading(false);
        }
      );
    }
  }, [alias, id, existing]);

  useEffect(() => {
    setMessage(null);
  }, [item]);

  function onSave(item) {
    setSaving(true);
    saveItem(item)
      .then(
        (result) => {
          if (result && result.errorMessage) {
            setError(result.errorMessage);
          }
          else {
            setMessage("Item saved");
            setError(null);
            setEditing(false);
          }
          setSaving(false);
        },
        (error) => {
          console.log(error);
          setError(JSON.stringify(error));
          setSaving(false);
        }
      )
      .finally(() => {
        console.log('finally');
        setSaving(false);
      });
  }

  let searchUserWrapper = (chars) => {
    setSearchingUsers(true);
    setUserList([])
    //console.log("searching for " + chars)
     searchUser(chars)
      .then(
        (result) => {
          if (result && result.errorMessage) {
            //setError(result.errorMessage);
          }
          else {
            //setMessage("Found Users");
            //console.log(result)
            let users = result.Items.map(item => {
              return {label: item.name, value: item.alias, description: item.alias}
            })
            //console.log(users)
            setUserList(users)
            setSearchingUsers(false);
          }
        },
        (error) => {
          console.log(error);
          //setError(JSON.stringify(error));
          setSaving(false);
        }

      ) 
  }

  function onDelete(item) {
    setSaving(true);
    console.log("***????")
    console.log(item)
    deleteItem(item)
      .then(
        (result) => {
          debugger;
          if (result && result.errorMessage) {
            setError(result.errorMessage);
          }
          else {
            setMessage("Item deleted");
            setError(null);
            setEditing(false);
          }
          setSaving(false);
          setDeleting(false);
          // navigate to author list
          history.push('/list/authors/' + alias);
        },
        (error) => {
          console.log(error);
          setError(JSON.stringify(error));
          setSaving(false);
        }
      )
      .finally(() => {
        console.log('finally');
        setSaving(false);
      });
  }

  function findAuthorById(id) {
    return { label: id, value: id }; // fallback
  }

  function toAuthorArray(inString) {
    if (inString && inString.split) {
      return inString.split(',').map(
        author => findAuthorById(author));
    }
    return [];
  }

  function toAuthorString(inArray) {
    return inArray.join(',');
  }

  function toTagArray(inString) {
    if (inString && inString.split) {
      //console.log("toTagArray: split: ", inString.split(','));
      return inString.split(',').map(
        area => {
          console.log("toTagArray: area: ", area);
          return findSelected(area, areas);
        });
    }
    return [];
  }

  function toTagString(inArray) {
    return inArray.join(',');
  }

  function arrayToVisibleString(inString) {
    if (inString && inString.split) {
      return inString.split(',').join(', ');
    }
    return '-';
  }

  function stringToArray(inString) {
    if (inString && inString.split) {
      return inString.split(',');
    }
    return [];
  }

  function findSelected(inItem, items) {
    if (inItem) {
      let category, option;
      for (let i in items) {
        category = items[i];
        for (let j in category.options) {
          option = category.options[j];
          //console.log('findSelected', inItem, category, option);
          if (option.value === inItem) {
            //console.log('findSelected found:', inItem, option);
            return { value: option.value, label: option.value };
          }
        }
      }
      console.log('findSelected not found', inItem);
    }
    return null;
  }

  const placeholderDescription = "When you create a new entry within Biblio, you can select a published, created work, or a proposed work in progress to invite collaboration and group artifact construction.";

  return loading ? <Spinner size="large" /> : (
    <SpaceBetween>
      {deleting && <Confirm 
        id={id} 
        dismiss={()=>setDeleting(false)}
        confirm={()=>onDelete(item)}/>
      }
          <Box
              margin={{bottom: "m"}}
            >
            <BreadcrumbGroup
            items={[
              { 
                text: "Biblio",
                href: "/"
              },
              { 
                text: existing ? "Detail" : "Create",
                href: existing ? "/item/edit/" + id : "/item/edit/create"
              }
            ]}/>
          </Box>

          {!error && 
            <SpaceBetween direction="vertical" size="l">
  
            <Container header={
              <Header 
                    variant="h2" 
                    xactions={
                      <SpaceBetween direction="horizontal">
                        {item.authors && item.authors.split && item.authors.split(',').map(AUTHOR_IMAGE)}
                      </SpaceBetween>}>
                    {item.title || "Create Entry"}
              </Header>
              }>
              {item.description || placeholderDescription}
            </Container>
  
            {(!editing) && 
            <Container header={
                <Header 
                actions={
                  !editing && 
                  stringToArray(item.authors).includes(alias) && 
                    <Button 
                      hidden={editing}
                      variant="primary" 
                      onClick={()=>setEditing(!editing)}>
                        Edit
                    </Button>
                }
                variant="h2">
                  Entry Properties
                </Header>}>
              <ColumnLayout columns={2} variant="text-grid">
                <SpaceBetween size="l">
                  <ValueWithLabel label="Entry Type">
                    {item.status || '-'}
                  </ValueWithLabel>
                  <ValueWithLabel label="Content Category">
                    {item.category || '-'}
                  </ValueWithLabel>
                  <ValueWithLabel label="Content Areas">
                    {arrayToVisibleString(item.tags)}
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                  <div style={{marginBottom:'-8px'}} >
                    {item.authors && item.authors.split && item.authors.split(',').map(AUTHOR_IMAGE)}
                   </div>
                  <ValueWithLabel label="Authors">
                    {item.authors && item.authors.split && item.authors.split(',').map(AUTHOR_NAME)}
                  </ValueWithLabel>
                  <ValueWithLabel label="Start Date">
                    {item.proposedDate || '-'}
                  </ValueWithLabel>
                </SpaceBetween>
              </ColumnLayout>
              <ColumnLayout columns={1} variant="text-grid">
                <SpaceBetween size="l">
                  <ValueWithLabel label="URL">
                  {item.url ?
                    <Link
                          external
                          externalIconAriaLabel="Opens in a new tab"
                          href={item.url}
                        >
                      {item.url}
                    </Link> :
                    <>-</>}
                  </ValueWithLabel>
                </SpaceBetween>
              </ColumnLayout>
            </Container>}
            
            {editing &&
            <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    {existing && 
                      <Button 
                        variant="link" 
                        visible={!saving}
                        onClick={()=>setEditing(false)}>
                          Cancel
                      </Button>}
                      <Button 
                        disabled={!item.title || !item.category || !item.description || !item.url}
                        variant="primary" 
                        loading={saving} 
                        onClick={()=> onSave(item)}>
                          Save Changes
                        </Button>
                  </SpaceBetween>
                }
              >
    
            <SpaceBetween direction="vertical" size="l">
              
              <Container header={
                  <Header 
                    actions={
                        <Button 
                          hidden={editing}
                          onClick={()=>setDeleting(true)}>
                            Delete
                        </Button>
                    }
                    variant="h2">
                    Entry Properties
                  </Header>
              }>
                <SpaceBetween direction="vertical" size="l">
                  <Tiles
                        onChange={(event) => setItem({ ...item, status: event.detail.value})}
                        value={item.status}
                        items={[
                        {
                          label: "Published / Created",
                          description: "Artifact has been completely constructed and published; speaking engagements have occurred and concluded.",
                          value: "Completed"
                        },
                        {
                          label: "Work in Progress",
                          description: "This is an incomplete artifact or proposed work in progress with a completion date in the future.",
                          value: "Proposed"
                        }
                      ]}
                      />
                  <FormField label="Title*" description="Enter the title of this artifact entry." errorText={item.title === '' && "Title is required"}>
                    <Input value={item.title} onChange={(event) => setItem({ ...item, title: event.detail.value})} />
                  </FormField>
                  <FormField label="Description*" description="Enter a description for this entry." errorText={item.description === '' && "Description is required"}>
                    <TextArea value={item.description} onChange={(event) => setItem({...item, description: event.detail.value})} />
                  </FormField>
                  <FormField label="Co-Authors" description="Who worked on this artifact?">
                        <Multiselect
                          filteringType="manual"
                          selectedOptions={toAuthorArray(item.authors)}
                          onChange={({ detail }) =>{
                              //console.log(detail)
                              setItem({...item, authors: toAuthorString(detail.selectedOptions.map(user => user.value))});
                            }
                          }
                          onLoadItems={(e) => {
                            //console.log(e.detail.filteringText)
                            if(e.detail.filteringText.length > 2){
                              //console.log('searching for user')
                              searchUserWrapper(e.detail.filteringText)
                            } else {
                              setSearchingUsers(true)
                              setUserList([])
                            }
                            

                          }

                          }
                          deselectAriaLabel={e => `Remove ${e.label}`}
                          options={userList}
                          placeholder="Search for Authors"
                          selectedAriaLabel="Selected"
                          statusType={searchingUsers? "loading" : "finished"}
                          loadingText="Searching for users"
                        />
                        
                  </FormField>    
                             
                  <FormField label="Content Areas" description="What is this content about?">
                        <Multiselect
                          filteringType="auto"
                          selectedOptions={toTagArray(item.tags)}
                          onChange={({ detail }) =>{
                              setItem({...item, tags: toTagString(detail.selectedOptions.map(e => e.value))});
                            }
                          }
                          deselectAriaLabel={e => `Remove ${e.label}`}
                          options={areas}
                          placeholder="Choose options"
                          selectedAriaLabel="Selected"
                        />
                  </FormField> 
                  <FormField label="Content Category" description="What type of content did you create?" errorText={item.category === '' && "Please select a valid value"}>
                    <Select
                      options={categories}
                      selectedOption={findSelected(item.category, categories)}
                      onChange={(event) => setItem({...item, category: event.detail.selectedOption.value})}
                      selectedAriaLabel="selected"
                    />
                  </FormField>
    
                  <FormField label="Technical Level" description="How specialized is this content?" errorText={item.level === '' && "Please select a valid value"}>
                    <Select
                      options={levels}
                      selectedOption={levels.find((status)=>status.value ===item.level)}
                      onChange={(event) => setItem({...item, level: event.detail.selectedOption.value})}
                      selectedAriaLabel="selected"
                    />
                  </FormField>
    
                  <FormField label="URL*" description="Where is this content located?"
                    errorText={item.url && !isURL(item.url) && "Please select a valid value"}>
                    <Input inputMode="url" type="url" value={item.url} onChange={(event) => setItem({ ...item, url: event.detail.value})} />
                  </FormField>

                  <FormField label="Completion Date">
                    <DatePicker
                      value={item.proposedDate}
                      onChange={(event) => setItem({...item, proposedDate: event.detail.value})}
                    />
                  </FormField>

                </SpaceBetween>
              </Container>
            </SpaceBetween>
          </Form>
          }
          </SpaceBetween>
  }
  <Alert 
          dismissable
          onDismiss={() => setError(null)}
          type='error' 
          visible={error !== null}>
            {error}
        </Alert> <
  Alert
  dismissable = { true }
  dismissAriaLabel = "Clear message"
  onDismiss = {
    () => setMessage(null)
  }
  type = 'success'
  visible = { message !== null } > { message }
  </Alert> 
  </SpaceBetween>

);
}
