import { Link as RouterLink } from "react-router-dom";

import {
    Box,
    Link,
    SpaceBetween,
    StatusIndicator
  }
  from "@awsui/components-react";

let styles = {
    container: {
        width: "10vw",
        minWidth: "150px",
        minHeight: "200px",
        height: "20vh",
        backgroundColor: "#DAEFFE",
        borderStyle: "solid",
        borderColor: "#00A721 #3EACFB #3EACFB #3EACFB",
        borderWidth: "5px 1px 1px 1px",
        position: "relative"
    },
    title: {
        margin: "auto",
        padding: "5%",
        borderStyle: "none none solid none",
        borderWidth: "1px",
        borderColor: "gray",
        backgroundColor: "#EEF3EF",
        height: "20px",
        overflow: "hidden"
    },
    titleText: {
        fontSize: "1em",
        fontWeight: "bold",
        lineHeight: "10px",
        fontWeigth: "bold",
        textAlign: "center",
        verticalAlign: "middle",
        lineHeight: "20px"
    },
    content: {
        fontSize: "1em",
        fontColor: "black",
        paddingLeft: "5%",
        overflow: "hidden",
        maxHeight: "inherit",
        position: "absolute"
    }
}

const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
  );
  

let NewOppCard = (props) => {
    let title = ""
    let authors = props.item.authors
    let tags = ""


    let maxTitleLength = 50;
    if (props.item.title.length > maxTitleLength){
        title = props.item.title.substring(0,maxTitleLength) + "..."
    } else {
        title = props.item.title
    }

    let maxAuthorLength = 15;
    if (props.item.authors.length > maxAuthorLength){
        authors = props.item.authors.substring(0,maxAuthorLength) + "..."
    } else {
        authors = props.item.authors
    }

    let maxTagLength = 15;
    if (props.item.tags?.length > maxTagLength){
        tags = props.item.tags.substring(0,maxTagLength) + "..."
    } else {
        tags = props.item.tags
    }

    return(
        <div style={styles.container}>
            <div style={styles.title}>
            <RouterLink to={"/item/"+props.item.id}>
              <Link>
                <div style={styles.titleText}>{props.item.category}</div>
              </Link>
              </RouterLink>
            </div>
            <div style={styles.content}>
            <SpaceBetween size="xxs">
                <ValueWithLabel label="Authors">
                    {authors}
                </ValueWithLabel>
                <StatusIndicator>Collaboration</StatusIndicator>
                <ValueWithLabel label="Tags">
                    {tags}
                </ValueWithLabel>
                
            </SpaceBetween>
            </div>
            
            
        </div>
    )
}

export default NewOppCard