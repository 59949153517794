import {
  Box,
  Button,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  SpaceBetween,
}
from "@awsui/components-react";
import { useState } from "react";

// eslint-disable-next-line
export default ({ id, dismiss, confirm, match }) => {
  const [deleteText, setDeleteText] = useState('');
  const confirmText = match || 'delete';

  return (
    <Modal
      visible={true}
      onDismiss={()=>dismiss()}
      header="Delete this item?"
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button 
              onClick={()=>dismiss()} 
              variant="link">Cancel</Button>
            <Button 
              onClick={()=>confirm()} 
              variant="primary" 
              disabled={deleteText !== confirmText}>
              Delete
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <ColumnLayout borders="horizontal">
        <Box margin={{ top: 'xxs' }}>
          <FormField label={"To confirm deletion, type '"+confirmText+"' in the text input field."} stretch={true}>
            <Input
              placeholder={confirmText}
              onChange={e => setDeleteText(e.detail.value)}
              value={deleteText}
            />
          </FormField>
        </Box>
      </ColumnLayout>
    </Modal>
  );
}
