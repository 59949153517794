import {
  Box,
  Button,
  Link,
  SpaceBetween,
}
from "@awsui/components-react";
import { Link as RouterLink } from "react-router-dom";

export const TableNoMatchState = props => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const TableEmptyState = ({ resourceName }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No {resourceName.toLowerCase()}s</b>
        <Box variant="p" color="inherit">
          No {resourceName.toLowerCase()}s associated with this resource.
        </Box>
      </div>
      <Button>Create {resourceName.toLowerCase()}</Button>
    </SpaceBetween>
  </Box>
);

export const AUTHOR_IMAGE = author =>
  <RouterLink
    to={"/author/"+author}>
      <img 
        alt={author}
        height="48px" 
        style={{border: "1px solid #aaa"}} 
        src={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid="+author}/>
    </RouterLink>;

export const AUTHOR_NAME = author =>
  <RouterLink
    to={"/author/"+author}>
      {author + ' '}
    </RouterLink>;

export const CARD_DEFINITIONS = {
    header: (item) =>
      <div style={{minHeight:'50px', textDecoration: 'none'}} >
            <RouterLink 
                to={"/item/"+item.id}>
                <Link fontSize="heading-m">{item.title}</Link>
            </RouterLink>
        </div>,
    sections: [{
          id: "photo",
          width: "50",
          content: (item) => <div style={{minHeight:'50px'}} >
                {item.authors && item.authors.split && item.authors.split(',').map(AUTHOR_IMAGE)}
            </div>
        },
        {
          id: "authors",
          header: < > Authors < />,
            width: "50",
            content: (item) => < > 
                {item.authors && item.authors.split && item.authors.split(',').map(AUTHOR_NAME)} </>
        },
        {
            id: "tags",
            header: < > Content Areas < />,
            width: "50",
            content: (item) => < > {
                (item.tags && item.tags.split(',').join(', ')) || '-'
            } < />
        },
        {
            id: "category",
            header: < > Categories < />,
            width: "50",
            content: (item) => < > { item.category || '-' } < />
        },
        {
            id: "level",
            header: < > Level < />,
            width: "50",
            content: (item) => < > { item.level || '-' } < />
        },
        {
            id: "status",
            header: < > Status < />,
            width: "50",
            content: (item) => < > { item.status || '-'  } < />
        },
        {
            id: "proposedDate",
            header: "Proposed Date",
            width: "50",
            content: (item) => < > { item.proposedDate || '-'  } < />
        },{
            id: "completedDate",
            header: "Completed date",
            width: "50",
            content: (item) => < > { item.status ==='Completed' ? item.modifiedOn : '-'  } < />
        },
        {
            id: "createdBy",
            header: < > Created By < />,
            width: "50",
            content: (item) => < > { item.createdBy || '-'  } < />
        },
        {
            id: "url",
            header: < > URL < />,
            width: "100",
            content: (item) => < > {item.url ?
                    <Link
                          external
                          externalIconAriaLabel="Opens in a new tab"
                          href={item.url}
                        >
                      {item.url}
                    </Link> : '-' } </>
        }    ]
};

export const VISIBLE_CONTENT_OPTIONS = [{
    label: 'Main item properties',
    options: [
        { id: 'photo', label: 'Photos' },
        { id: 'authors', label: 'Authors' },
        { id: 'category', label: 'Category' },
        { id: 'level', label: 'Level' },
        { id: 'tags', label: 'Content Areas' },
        { id: 'status', label: 'Status' },
        { id: 'proposedDate', label: 'Proposed Date' },
        { id: 'createdBy', label: 'Created By' },
        { id: 'url', label: 'URL' }
    ]
}];

export const PAGE_SIZE_OPTIONS = [
    { value: 10, label: '10 items' },
    { value: 30, label: '30 items' },
    { value: 50, label: '50 items' }
];

export const DEFAULT_PREFERENCES = {
    pageSize: 30,
    visibleContent: ['photo', 'authors', 'category', 'level', 'tags', 'area', 'subarea', 'status', 'proposedDate', 'completedDate', 'createdBy'],
};
