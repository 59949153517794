import * as React from "react";
import {
    Alert,
    Box,
    BreadcrumbGroup,
    Container,
    Header,
    SpaceBetween,
    Table,
    Icon,
    Tabs,
    Button,
    TextFilter,
    Pagination,
    CollectionPreferences,
    Link,
    Grid,
    PieChart
  }
  from "@awsui/components-react";


import { useState, useEffect } from "react";


import { getDirectReports } from "./API";





export default (props) => {
  const [directReports, setDirectReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalMetrics, setTotalMetrics] = useState([0, 0]);
  const [error, setError] = useState(null);
  const [chartStatus, setChartStatus] = useState("loading");

  useEffect(() => {
    if(directReports.length != 0){
      return;
    }
      getDirectReports(props.mgrAlias, true).then(
        (result) => {
          if (result && result.errorMessage) {
            setDirectReports([]);
            setError(result.errorMessage);
          }
          else {
            setDirectReports(result);
            calcAggregateMetrics(result)
            setError(null);
          }
          setIsLoading(false);
        },
        (error) => {
          console.log(error);
          setError(JSON.stringify(error));
          setIsLoading(false);
        }
      );

  })
  
  const calcAggregateMetrics = (reports) => {
    let inProgress = 0;
    let completed = 0;
    reports.forEach( report => {
      inProgress = inProgress + report.tlItemsInProgress
      completed = completed + report.tlItemsCompleted
    });
    setTotalMetrics([completed, inProgress])
    setChartStatus("finished")
  }


  const [
    selectedItems,
    setSelectedItems
  ] = React.useState([{ name: "Item 2" }]);
  return (
    <Grid
      gridDefinition={[{ colspan: 12}]}
    >
      <Table
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            cell: e => <Link href={e.isManager ? "/manager/"+ e.alias : "/author/"+ e.alias } external> {e.name + " ("+e.alias+")"}</Link>,
            sortingField: "name",
            width: 350,
            minWidth: 200
          },
          {
            id: "role",
            header: "Role",
            cell: e => {return e.isManager ? "Manager" : "IC" },
            sortingField: "isManager",
            width: 100,
            minWidth: 100
          },
          { 
            id: "inProgress", 
            header: "In Progress", 
            cell: e => e.tlItemsInProgress,
            width: 150,
            minWidth: 100
          },
          {
            id: "completed",
            header: "Completed",
            cell: e => e.tlItemsCompleted,
            width: 150,
            minWidth: 100
          }
        ]}
        items={directReports}
        loadingText="Loading resources"
        visibleColumns={[
          "name",
          "role",
          "inProgress",
          "completed"
        ]}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No resources</b>
            <Box
              padding={{ bottom: "s" }}
              variant="p"
              color="inherit"
            >
              No resources to display.
            </Box>
          </Box>
        }
        loading={isLoading}
        resizableColumns
        header={
          <Header>
            Direct Reports
          </Header>
        }
      />
    </Grid>
  );

    }