import {
    Box,
    BreadcrumbGroup,
    Container,
    Header,
    SpaceBetween,
    Table,
    Icon,
    Tabs
  }
  from "@awsui/components-react";
  import { useState, useEffect, useRef } from "react";
  import { useParams } from "react-router-dom";
  import { Link } from "react-router-dom";
  import { users } from "./Enumerations";
  import { getDashboardURL, getItems } from "./API";
  import { getUser } from "./API";
  import DirectReportsTable from "./DirectReportsTable";
  import Iframe from 'react-iframe'
  var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");



let getURL = async (div) => {
      
    await getDashboardURL().then(
      (result) => {
        if (result && result.errorMessage) {
          //setError(result.errorMessage);
        }
        else {
          console.log("dashboard url " + result.EmbedUrl)
          let url = result.EmbedUrl
          let options = {
            url: url,
            container: div,
            parameters: {
                country: "United States"
            },
            scrolling: "no",
            height: "800px",
            width: "100%",
            footerPaddingEnabled: true
        }
            console.log(QuickSightEmbedding)
          const dashboard = QuickSightEmbedding.embedSession(options);
        }
      },
      (error) => {
        console.log(error);
        //setError(JSON.stringify(error));

      }
    );
  }



let Dashboard = () => {
    const containerRef = useRef(null);

    useEffect(() => {
        
        console.log("container Div");
        console.log(containerRef.current);
        getURL(containerRef.current)
    }, [])

    return (
        <div ref={containerRef}>
            
        </div>
    )

}


export default Dashboard;