import AppLayout from "@awsui/components-react/app-layout";
import Author from "./components/Author";
import Manager from "./components/Manager";
import ItemEdit from "./components/ItemEdit";
import ItemCards from "./components/ItemCards";
import Home from "./components/Home";
import TopNavigation from "./components/TopNavigation";
import SideNavigation from "@awsui/components-react/side-navigation";
import React, { useState } from "react";
import {
  Icon
}
from "@awsui/components-react";
import {
  BrowserRouter as Router,
  Switch,
  Route
}
from "react-router-dom";
import "./App.css";

import { getUser, addUserActivity } from "./components/API";

//note: redirects if not authenticated
import getMidwayToken from "./components/MidwayToken";
const midwayToken = getMidwayToken();
let alias = '';
if (midwayToken && midwayToken.payload && midwayToken.payload.sub) {
  console.log("***VERSION***")
  console.log("1.2.1")
  alias = midwayToken.payload.sub;
  //alias = 'rajkrshn';
  
  sessionStorage.setItem("alias", alias);
  
  addUserActivity(alias)

}


// if we get this far, we're authenticated


  


let ICSideNavItems = 
  [
    {/* type: "link", text: "All items", href: "/list".*/},
    { type: "link", text: "My items", href: "/author/"+alias},
    { type: "divider" },
    { type: "link", text: "Completed items", href: "/list/status/Completed"},
    { type: "link", text: "Proposed items", href: "/list/status/Proposed"},
    { type: "divider" },
    { type: "link", text: "Browse by author", href: "/list/authors"},
    { type: "link", text: "Browse by category", href: "/list/category"},
    { type: "link", text: "Browse by topic", href: "/list/tags"},
    { type: "divider" },
    { type: "link", text: "Create new item", href: "/item/create", info: <Icon name="add-plus"></Icon> },
    { type: "divider" },
    { type: "link", text: "User Guide", href: "https://w.amazon.com/bin/view/AWS_Americas_Solution_Architecture_Team/AWS_SA_US-SMB/Biblio/", external: true},
    { type: "link", text: "Submit a ticket", href: "https://issues.amazon.com/issues/create?template=6387deae-eeee-4ef9-a23b-8a7ea95eeb34", external: true},
    { type: "divider" },
    { type: "link", text: "Speaker Finder", href: "https://find.aws-speakers.com/ ", external: true}
  ]

  


  let  managerSideNavItems = [
      {/* type: "link", text: "All items", href: "/list".*/},
      { type: "link", text: "My items", href: "/author/"+alias},
      { type: "divider" },
      { type: "link", text: "Completed items", href: "/list/status/Completed"},
      { type: "link", text: "Proposed items", href: "/list/status/Proposed"},
      { type: "divider" },
      { type: "link", text: "Browse by author", href: "/list/authors"},
      { type: "link", text: "Browse by category", href: "/list/category"},
      { type: "link", text: "Browse by topic", href: "/list/tags"},
      { type: "divider"},
      { type: "link", text: "Manager View", href: "/manager/"+alias },
      { type: "divider" },
      { type: "link", text: "Create new item", href: "/item/create", info: <Icon name="add-plus"></Icon> },
      { type: "divider" },
      { type: "link", text: "User Guide", href: "https://w.amazon.com/bin/view/AWS_Americas_Solution_Architecture_Team/AWS_SA_US-SMB/Biblio/", external: true},
      { type: "link", text: "Submit a ticket", href: "https://issues.amazon.com/issues/create?template=6387deae-eeee-4ef9-a23b-8a7ea95eeb34", external: true},
      { type: "divider" },
      { type: "link", text: "Speaker Finder", href: "https://find.aws-speakers.com/ ", external: true}
    ]




// eslint-disable-next-line
export default () => {
  const [sideNavItems, setSideNavItems] = useState(ICSideNavItems);
  getUserName(alias)
  

  async function getUserName(alias) {
    let user = await getUser(alias)
    let name = alias
    let isManager = false
    let isTester = false
  
    if (user.Item !== undefined) {
      name = user.Item.name
      isManager = user.Item.isManager
      isTester = user.Item.isTester
      console.log("user item")
      console.log(user.Item)
    }

    sessionStorage.setItem("name", name)
    sessionStorage.setItem("isManager", isManager)
    sessionStorage.setItem("isTester", isTester)
    if (sessionStorage.getItem("isManager")==="true" || sessionStorage.getItem("isTester")==="true"){
      setSideNavItems(managerSideNavItems)
    }
  }
  return (
    <Router>
      <div id="h" style={{ position: "sticky", top: 0, zIndex: 1002 }}>
      <TopNavigation />
      </div>
    
    <AppLayout
    toolsHide={true}
      navigation={
        <div id="s" style={{ marginTop: "100px"}}>
          <SideNavigation items={sideNavItems}/>
        </div>
      }
      content={
        <Switch>
          <Route path="/author/:id">
            <Author />
          </Route>
          <Route path="/author/">
            <Author />
          </Route>
          <Route path="/manager/:id">
            <Manager />
          </Route>
          <Route path="/item/edit/:id">
            <ItemEdit />
          </Route>
          <Route path="/item/:id">
            <ItemEdit />
          </Route>
          <Route path="/list/:key/:value">
            <ItemCards />
          </Route>
          <Route path="/list/:key">
            <ItemCards />
          </Route>
          <Route path="/list">
            <ItemCards />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      }
    />
    </Router>
  );
};
