import {
  ColumnLayout,
  Container,
  Header,
  Box,
  Grid,
  SpaceBetween,
  Link,
  Button,
  Icon,
  Spinner
}
from "@awsui/components-react";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { getRecentItems } from "./API";
import "../styles/home.css";
import NewOppCard from "./NewOppCard";

// eslint-disable-next-line
export default () => {
  const [publishedItems, setPublishedItems] = useState([]);
  const [proposedItems, setProposedItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    setLoading(true);
    getRecentItems("Completed", 6).then(
      (result) => {
        console.log('Recent Items')
        console.log(result)
        setPublishedItems(result.Items);
        //setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );

    getRecentItems("Proposed", 6).then(
      (result) => {
        setProposedItems(result.Items);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );

  }, []);


  return (
    <div>
    <div className="">
    <div className="custom-home__header">
        <Box padding={{ vertical: "xxxl", horizontal: "s" }}>
          <Grid
            gridDefinition={[
              { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
              {
                colspan: { xl: 6, l: 5, s: 6, xxs: 10 },
                offset: { l: 2, xxs: 1 },
              },
              {
                colspan: { xl: 2, l: 3, s: 4, xxs: 10 },
                offset: { s: 0, xxs: 1 },
              },
            ]}
          >


            <div className="custom-home__header-title">
              <Box
                fontWeight="light"
                padding={{ bottom: "s" }}
                fontSize="display-l"
                color="inherit"
              >
                Discover, Track, and Collaborate on Thought Leadership.
              </Box>
              <Box variant="p" fontWeight="light">
                <span className="custom-home__header-sub-title">
                  Biblio enables Solutions Architects to discover and track Thought Leadership activities across their orgs.

                </span>
              </Box>
              
            </div>
                <Button href="/item/create" variant="primary">
                  Get started
                </Button>


          </Grid>
          </Box>
        </div>
        </div>

        
        <div style={{ width: "100%"}}>
        <Grid
      gridDefinition={[
        { colspan: 6 }, { colspan: 6 }
      ]} >
        <div>
        <h2>Recently Completed</h2>
        
        <Container>
          {publishedItems.map(item=>
          <Container style={{minHeight: "150px", maxHeight: "150px"}} key={item.id}
            header={
              <div/>
            }
          >
            <img height="90px" alt={item.createdBy||"none"} style={{float: "left", marginRight: "24px", border: "1px solid #aaa"}} src={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid="+(item.createdBy||"none")}/>
            <div>
              <RouterLink to={"/item/"+item.id}>
              <Link>
                <h3>{item.title}</h3>
              </Link>
              </RouterLink>
              <p>{item.description? item.description.substring(0, 400): item.category}</p>
            </div>
          </Container>
          )}
          <p align="right" style={{marginBottom: "0px"}}>
            <RouterLink to="/list/status/Proposed">
            <Link>Browse Completed Works</Link>
            </RouterLink>
          </p>
        </Container>
        </div>
        
        <div>
        <h2>Recently Proposed</h2>
        
        <Container>
          {proposedItems.map(item=>
          <Container style={{minHeight: "150px", maxHeight: "150px"}} key={item.id}
            header={
            <div />

            }
          >
            <img height="90px" alt={item.createdBy||"none"} style={{float: "left", marginRight: "24px", border: "1px solid #aaa"}} src={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid="+(item.createdBy||"none")}/>
            <div>
              <RouterLink to={"/item/"+item.id}>
              <Link>
                <h3>{item.title}</h3>
              </Link>
              </RouterLink>
              <p>{item.description? item.description.substring(0, 400): item.category}</p>
            </div>
          </Container>
          )}
          <p align="right" style={{marginBottom: "0px"}}>
            <RouterLink to="/list/status/Proposed">
            <Link>Browse Proposed Works</Link>
            </RouterLink>
          </p>
        </Container>
        </div>
      {
        loading && 
          <div style={{paddingTop: "30px"}}>
            <Spinner size="big"/>
          </div>
      }
    </Grid>
    </div>
    </div>
  );
}
