const STAGE = "PROD"

const PROD_URLS = {
  "TL_SERVICE_URL": "https://yx7q5quvp9.execute-api.us-east-1.amazonaws.com/dev/"
}

const DEV_URLS = {
  "TL_SERVICE_URL": "https://s2nb9t13f9.execute-api.us-east-1.amazonaws.com/dev/"
}

let SERVICE_URLS = ""

if(STAGE === "PROD"){
  SERVICE_URLS = PROD_URLS
} else if(STAGE === "DEV") {
  SERVICE_URLS = DEV_URLS
} else {
  console.log("ERROR: Operating Mode Not Defined. Specify Prod or Dev.")
}


async function getItems(filterProps) {
  let fetchUrl = SERVICE_URLS.TL_SERVICE_URL + "tl/";
  if (filterProps) {
    fetchUrl =  SERVICE_URLS.TL_SERVICE_URL + "tl/search?";
    for (const key in filterProps) {
      fetchUrl = fetchUrl +
        encodeURIComponent(key) + "=" +
        encodeURIComponent(filterProps[key]) + "&";
    }
    fetchUrl = fetchUrl.substring(0, fetchUrl.length - 1);
  }
  return internalFetch(fetchUrl);
}


async function getRecentItems(status, limit) {
  let fetchUrl = SERVICE_URLS.TL_SERVICE_URL + "tl/recent?status=" +status + "&limit=" + limit;

  return internalFetch(fetchUrl);
}

async function getUsers(filterProps) {
  return new Promise((resolve, reject) => {
    let fetchUrl = SERVICE_URLS.TL_SERVICE_URL + "tl/aliases";
    //console.log("filter Props: " + filterProps)
    if (filterProps) {
      fetchUrl = SERVICE_URLS.TL_SERVICE_URL + "tl/aliases";
      for (const key in filterProps) {
          fetchUrl = fetchUrl +
          encodeURIComponent(key) + "=" +
          encodeURIComponent(filterProps[key]) + "&";
      }
      fetchUrl = fetchUrl.substring(0, fetchUrl.length - 1);
    }
    internalFetch(fetchUrl).then(result => {
      let items = result.Items;
      items = items.sort((a, b) => ('' + a.label).localeCompare(b.label));
      resolve(items);
    });
  });
}


async function getDashboardURL() {
  let fetchUrl = SERVICE_URLS.TL_SERVICE_URL + "tl/dashboards?mode=getUrl";
return internalFetch(fetchUrl);
}

async function getDirectReports(alias, metrics = false) {
//metrics -  boolean value to inclue TL count for each report
let fetchUrl = SERVICE_URLS.TL_SERVICE_URL + `aliases/reports?alias=${alias}&metrics=${metrics}`;
return internalFetch(fetchUrl);
}

async function getUser(alias) {
  let fetchUrl = SERVICE_URLS.TL_SERVICE_URL + "tl/aliases?" + "alias=" + alias;
  return internalFetch(fetchUrl);
}

async function searchUser(chars) {
  let fetchUrl = SERVICE_URLS.TL_SERVICE_URL + "tl/aliases/scan?" + "chars=" + chars;
  return internalFetch(fetchUrl);
}

async function addUserActivity(alias) {
  let fetchUrl = SERVICE_URLS.TL_SERVICE_URL + "tl/activity?" + "alias=" + alias;
  return internalFetch(fetchUrl);
}

async function internalFetch(fetchUrl) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    /* global fetch */
    console.log(fetchUrl)
    fetch(fetchUrl, {
        headers: {
          "Authorization": "Bearer " + sessionStorage.getItem("midway_id_token")
        },
      })
      .then(
        res => res.json(),
        error => {
          console.log("fetch", error);
          reject(error);
        }
      )
      .then(
        (result) => {
          if (result && result.errorMessage) {
            reject(result.errorMessage);
          }
          else if (result && result.body) {
            resolve(result.body);
          }
          else {
            resolve(result);
          }
        },
        (error) => {
          console.log("fetch", error);
          reject(error);
        }
      );
  });
}

async function saveItem(item) {
  let fetchUrl = SERVICE_URLS.TL_SERVICE_URL + "tl/";
  console.log("*******")
  console.log(item)
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    /* global fetch */
    fetch(fetchUrl, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(item)
      })
      .then(res => res.json())
      .then(
        (result) => {
          if (result && result.errorMessage) {
            reject(result.errorMessage);
          }
          else {
            resolve(result);
          }
        },
        (error) => {
          console.log("saveItem", error);
          reject(error);
        }
      );
  });
}

async function deleteItem(item) {
  //let category = item.category
  //let user = item.alias
  //let id = item.id
  //console.log("category: " + category + " user: " + user + " id: " + id)
  console.log("*******")
  console.log(item)
  let fetchUrl = SERVICE_URLS.TL_SERVICE_URL + "tl/";
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    /* global fetch */
    fetch(fetchUrl, {
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(item)
      })
      .then(res => res.json())
      .then(
        (result) => {
          if (result && result.errorMessage) {
            reject(result.errorMessage);
          }
          else {
            resolve(result);
          }
        },
        (error) => {
          console.log("deleteItem", error);
          reject(error);
        }
      );
  });
}



export { getItems, saveItem, deleteItem, getUsers, getUser, addUserActivity, getDirectReports, getDashboardURL, searchUser, getRecentItems };
