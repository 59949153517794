import {
  Alert,
  Box,
  BreadcrumbGroup,
  Container,
  Header,
  SpaceBetween,
  Table,
  Icon
}
from "@awsui/components-react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { getItems } from "./API";
import { getUser } from "./API";

// eslint-disable-next-line
export default () => {
  const [error, setError] = useState(null);
  const [proposedItems, setProposedItems] = useState([]);
  const [publishedItems, setPublishedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const alias = sessionStorage.getItem("alias");
  //const name = sessionStorage.getItem("name");

  const value = id || alias;


  const [name, setName] = useState(id);

  getUserName(id)
  async function getUserName(alias) {
    let user = await getUser(alias)
    let name = alias
  
    if (user.Item !== undefined) {
      name = user.Item.name
    }
    setName(name)
  }


  useEffect(() => {
    setLoading(true);
    setError(null);

    getItems({ authors: id }).then(
      (result) => {
        if (result && result.errorMessage) {
          setError(result.errorMessage);
        }
        else {
          setError(null);
        }
        const items = result || [];
        setProposedItems(items.filter(item => item.status === "Proposed"));
        setPublishedItems(items.filter(item => item.status === "Completed"));
        setLoading(false);
      },
      (error) => {
        setError(error.message);
        setLoading(false);
      }
    );
  }, [id]);

  return (
    <SpaceBetween directio="vertical" size="l">

      <Box
        margin={{bottom: "m"}}
      >
        <BreadcrumbGroup
          items={[
            { 
              text: "Biblio",
              href: "/"
            },
            { 
              text: "Authors",
              href: "/list/authors/"
            },
            { 
              text: value,
              href: "/author/" + value
            },
          ]}
        />
      </Box>
      
      <Container>
        <SpaceBetween direction="horizontal" size="m">
          <img alt={value||"none"} float="left" height="128px" style={{border: "1px solid #aaa"}} src={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid="+(value||"none")}/>
          <Header description={value} >
            <a target="_blank" rel="noopener noreferrer" href={"https://phonetool.amazon.com/users/" + value} style={{color:'#37475A'}}>
              {name +" "}
              <Icon name="external"/>
            </a>
          
          </Header>
        </SpaceBetween>
      </Container>
      
      { error === null && 
          <Table
            header={
              <Header>Published Works</Header>
            }          
            empty={"No items found"}
            resizableColumns="true"
            loading={loading}
            loadingText="Loading items"
            stickyHeader="true"
            trackBy="id"
            onNotRowClick ={(e) => console.log(e.detail.item.id)}
            columnDefinitions={[
              {
                id: "title",
                header: <>Title</>,
                width: "33%",
                cell: (item) => <><Link to={"/item/"+item.id}>{item.title}</Link></>
              },
              {
                id: "description",
                header: <>Description</>,
                cell: (item) => <>{item.description}</>
              },
              {
                id: "createdDate",
                header: <>Created Date</>,
                cell: (item) => <>{item.createdOn}</>
              }
            ]}
            items={publishedItems}
          ></Table> 
      }
      
      { error === null && 
          <Table
            header={
              <Header>Proposed Works</Header>
            }          
            empty={"No items found"}
            resizableColumns="true"
            loading={loading}
            loadingText="Loading items"
            stickyHeader="true"
            trackBy="id"
            onNotRowClick ={(e) => console.log(e.detail.item.id)}
            columnDefinitions={[
              {
                id: "title",
                header: <>Title</>,
                width: "33%",
                cell: (item) => <><Link to={"/item/"+item.id}>{item.title}</Link></>
              },
              {
                id: "description",
                header: <>Description</>,
                cell: (item) => <>{item.description}</>
              }
            ]}
            items={proposedItems}
          ></Table> 
      }

      <Alert type='error' visible={error !== null}>{error}</Alert>
      

    </SpaceBetween>

  );
}
