import {
  Alert,
  Box,
  BreadcrumbGroup,
  Cards,
  CollectionPreferences,
  Container,
  Grid,
  Header,
  Pagination,
  Select,
  SpaceBetween,
  TextFilter,
  DateRangePicker
}
from "@awsui/components-react";
import { useCollection } from '@awsui/collection-hooks';
import { useState, useEffect, useMemo } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { areas, categories, teams, statuses, levels, users } from "./Enumerations";
import {
  CARD_DEFINITIONS,
  VISIBLE_CONTENT_OPTIONS,
  PAGE_SIZE_OPTIONS,
  DEFAULT_PREFERENCES
}
from './Common';
import {
  TableEmptyState,
  TableNoMatchState
}
from './Common';
import { getItems } from "./API";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

// eslint-disable-next-line
export default () => {
  const history = useHistory();
  const query = useQuery();
  const [error, setError] = useState(null);
  const [entries, setEntries] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const { key, value } = useParams();
  const enumerations = { authors: users, tags: areas, category: categories, team: teams, status: statuses, level: levels, user: users };
  const { items, actions, filteredItemsCount, filterProps, paginationProps } = useCollection(
    entries, {
      filtering: {
        empty: <TableEmptyState resourceName="Distribution" />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: preferences.pageSize },
      selection: {}
    }
  );

  let dateRange = {};
  if (query.get("startDate")) {
    dateRange["startDate"] = query.get("startDate");
    dateRange["type"] = "absolute";
  }
  if (query.get("endDate")) {
    dateRange["endDate"] = query.get("endDate");
    dateRange["type"] = "absolute";
  }
  if (query.get("unit")) {
    dateRange["unit"] = query.get("unit");
    dateRange["type"] = "relative";
  }
  if (query.get("amount")) {
    dateRange["amount"] = query.get("amount");
    dateRange["type"] = "relative";
  }
  if (Object.keys(dateRange).length === 0) {
    dateRange = null;
  }
  const [range, setRange] = useState(dateRange);

  let userVisibleKey = key;
  if (key === 'authors') {
    userVisibleKey = 'author';
  }
  if (key === 'tags') {
    userVisibleKey = 'content area';
  }
  // capitalize
  userVisibleKey = userVisibleKey.charAt(0).toUpperCase() + userVisibleKey.substring(1);

  const options = [{ label: 'All items' }].concat(enumerations[key]);
  useEffect(() => {
    console.log("getting items")
    const conversions = {
      'minute': 1000 * 60,
      'hour': 1000 * 60 * 60,
      'day': 1000 * 60 * 60 * 24,
      'week': 1000 * 60 * 60 * 24 * 7,
      'month': 1000 * 60 * 60 * 24 * 30,
      'year': 1000 * 60 * 60 * 24 * 365
    }
  
    setLoading(true);
    setError(null);
    const params = {};
    params[key] = value;
    getItems((key && value) ? params : null).then(
      (result) => {
        if (result && result.errorMessage) {
          setError(result.errorMessage);
        }
        else {
          setError(null);
        }
        result = result || [];
        console.log(result)
        result.sort((a, b) =>
          (b.proposedDate || '').localeCompare((a.proposedDate || '')));
        if (range) {
          console.log(range);
          result = result.filter(item => {
            if (!item.proposedDate) {
              return false;
            }
            //note: adding "T12" in case proposed date has no timestamp
            if (range.startDate && range.startDate + "T01" > item.proposedDate + "T12") {
              return false;
            }
            if (range.endDate && range.endDate + "T24" < item.proposedDate + "T12") {
              return false;
            }
            if (range.amount && conversions[range.unit]) {

              if (new Date(item.proposedDate).getTime() <
                new Date().getTime() -
                conversions[range.unit] * range.amount) {
                return false;
              }
            }
            return true;
          });
        }
        setEntries(result);
        setLoading(false);
      },
      (error) => {
        console.log("Error: ", error, JSON.stringify(error));
        setError(error.message);
        setLoading(false);
      }
    );
  }, [key, value, range]);

  const onOptionChange = (option) => {
    const location = history.location.pathname;
    const lastIndex = location.lastIndexOf(key);
    const destination = {
      pathname: location.substring(0, lastIndex + key.length) + '/' + (option || '')
    };
    if (history.location.search) {
      destination.search = history.location.search;
    }
    history.push(destination);
  };

  const updateDateRange = (dateRange) => {
    setRange(dateRange);
    let location = "?";
    if (dateRange) {
      if (dateRange.startDate) {
        location = location + "startDate=" + dateRange.startDate + "&";
      }
      if (dateRange.endDate) {
        location = location + "endDate=" + dateRange.endDate + "&";
      }
      if (dateRange.amount) {
        location = location + "amount=" + dateRange.amount + "&";
      }
      if (dateRange.unit) {
        location = location + "unit=" + dateRange.unit + "&";
      }
    }
    location = location.substring(0, location.length - 1);
    history.push({
      pathname: history.location.pathname,
      search: location
    });
  };

  const findSelectedOption = (inValue, inOptions) => {
    for (let i in inOptions) {
      //      console.log("checking", inValue, inOptions[i]);
      if (inOptions[i].value === inValue) {
        return inOptions[i];
      }
      if (inOptions[i].options) {
        let recursiveResult = findSelectedOption(inValue, inOptions[i].options);
        if (recursiveResult !== null) {
          return recursiveResult;
        }
      }
    }
    return null;
  }

  const getFilterCounterText = (inCount) => {
    return inCount + " " + ((inCount === 1) ? "item" : "items");
  }

  return (
    <SpaceBetween>
      {key && 
      <>
        <Box
          margin={{bottom: "m"}}
        >
        <BreadcrumbGroup
          items={[
            { 
              text: "Biblio",
              href: "/"
            },
            { 
              text: "Items by " + userVisibleKey,
              href: "/list/category"
            },
          ]}
        />
        </Box>
        <Container 
          header={<Header
            actions={key === 'user' && <img alt={value||"none"} height="64px" style={{border: "1px solid #aaa"}} src={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid="+(value||"none")}/>}
            >Items {key && <span>by <span style={{textTransform: 'capitalize'}}>{userVisibleKey}</span></span>}</Header>}>
          <Grid
                gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}
              >
            <Select
              filteringType="auto"
              options={options}
              selectedOption={findSelectedOption(value, options) || options[0]}
              onChange={(event) => {onOptionChange(event.detail.selectedOption.value)}}
              selectedAriaLabel="selected"
            />
            <DateRangePicker
              value={dateRange}
              onChange={({ detail }) => updateDateRange(detail.value)}
              i18nStrings={{
                todayAriaLabel: "Today",
                nextMonthAriaLabel: "Next month",
                previousMonthAriaLabel: "Previous month",
                customRelativeRangeDurationLabel: "Duration",
                customRelativeRangeDurationPlaceholder:
                  "Enter duration",
                customRelativeRangeOptionLabel: "Custom range",
                customRelativeRangeOptionDescription:
                  "Set a custom range in the past",
                customRelativeRangeUnitLabel: "Unit of time",
                formatRelativeRange: e => {
                  const t =
                    1 === e.amount ? e.unit : `${e.unit}s`;
                  return `Last ${e.amount} ${t}`;
                },
                formatUnit: (e, t) => (1 === t ? e : `${e}s`),
                relativeModeTitle: "Relative range",
                absoluteModeTitle: "Absolute range",
                relativeRangeSelectionHeading: "Choose a range",
                startDateLabel: "Start date",
                endDateLabel: "End date",
                startTimeLabel: "Start time",
                endTimeLabel: "End time",
                clearButtonLabel: "Clear",
                cancelButtonLabel: "Cancel",
                applyButtonLabel: "Apply"
              }}
              dateOnly={true}
              placeholder="Filter by a date and time range"
            />  
          </Grid>
        </Container>
      </>
      }
      <Alert type='error' visible={error !== null}>{error}</Alert>
      { error === null && 
          <Cards
            empty={"No items found"}
            loading={loading}
            loadingText="Loading items"
            stickyHeader="true"
            selectionType="multi"
            trackBy="id"
            onSelectionChange={event => setSelectedItems(event.detail.selectedItems)}
            selectedItems={selectedItems}
            visibleSections={preferences.visibleContent}
            cardDefinition={CARD_DEFINITIONS}
            items={items}
            filter={
              <TextFilter
                {...filterProps}
                filteringAriaLabel="Filter by text content"
                filteringPlaceholder="Filter by text content"
                countText={getFilterCounterText(filteredItemsCount)}
                disabled={loading}
              />
            }
            pagination={
              <Pagination {...paginationProps} 
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }} 
                disabled={loading} />}
            preferences={
              <CollectionPreferences
                title="Preferences"
                confirmLabel="Confirm"
                cancelLabel="Cancel"
                disabled={loading}
                preferences={preferences}
                onConfirm={({ detail }) => setPreferences(detail)}
                pageSizePreference={{
                  title: 'Page size',
                  options: PAGE_SIZE_OPTIONS
                }}
                visibleContentPreference={{
                  title: 'Select visible columns',
                  options: VISIBLE_CONTENT_OPTIONS
                }}
                />
            }
          ></Cards> 
      }
      </SpaceBetween>

  );
}
