import { getUsers } from "./API";

const users = [];
getUsers().then(result => {
	//for (const i in result) {
		//users.push(result[i]);
	//}
	result.forEach((user, i) => {
		users.push({
				"label": user.name,
				"value": user.alias
			})
	})
	users.push(result)

});

const categories = [{
		"label": "Speaking & Events",
		options: [{
				value: "Workshop"
			},
			{
				value: "Immersion Day"
			},
			{
				value: "Game Day"
			},
			{
				value: "Chalk Talk"
			},
			{
				value: "Tech Talk"
			},
			{
				value: "Lunch & Learn"
			},
			{
				value: "Summit / Conference"
			},
			{
				value: "Podcast / Interview"
			}
		]
	},
	{
		"label": "Writing & Building",
		options: [{
				value: "Narrative"
			},
			{
				value: "Publication / Article"
			},
			{
				value: "Blog"
			},
			{
				value: "PR/FAQ"
			},
			{
				value: "Whitepaper"
			},
			{
				value: "Code / Demo"
			}
		]
	},
];

const categoryTypes = [{
	"category": "Workshop",
	"categoryType": "External Speaking"
}, {
	"category": "Immersion Day",
	"categoryType": "External Speaking"
}, {
	"category": "Narrative",
	"categoryType": "Writing"
}, {
	"category": "Lunch&Learn",
	"categoryType": "External Speaking"
}, {
	"category": "Publication/Article",
	"categoryType": "Writing"
}, {
	"category": "Blog",
	"categoryType": "Writing"
}, {
	"category": "Summit/Conference",
	"categoryType": "External Speaking"
}, {
	"category": "Tech Talk",
	"categoryType": "External Speaking"
}, {
	"category": "Chalk Talk",
	"categoryType": "External Speaking"
}, {
	"category": "PR/FAQ",
	"categoryType": "Writing"
}, {
	"category": "Game Day",
	"categoryType": "External Speaking"
}, {
	"category": "Podcast/Interview",
	"categoryType": "External Speaking"
},{
	"category": "Whitepaper",
	"categoryType": "Writing"
}];

const teams = [{
	label: "SMB",
	value: "smb"
}];

const statuses = [{
		label: "Proposed",
		value: "Proposed"
	},
	{
		label: "Completed",
		value: "Completed"
	}
];

const levels = [{
		label: "100",
		value: "100"
	},
	{
		label: "200",
		value: "200"
	},
	{
		label: "300",
		value: "300"
	},
	{
		label: "400",
		value: "400"
	},
	{
		label: "500",
		value: "500"
	}
];

const areas = [
	{
		label: "Analytics",
		options: [
			{ value: "Athena" },
			{ value: "AWS Clean Rooms" },
			{ value: "CloudSearch" },
			{ value: "AWS Data Exchange" },
			{ value: "Data Pipeline" },
			{ value: "Amazon DataZone" },
			{ value: "EMR" },
			{ value: "Amazon FinSpace" },
			{ value: "AWS Glue" },
			{ value: "AWS Glue DataBrew" },
			{ value: "Kinesis" },
			{ value: "AWS Lake Formation" },
			{ value: "MSK" },
			{ value: "Amazon OpenSearch Service" },
			{ value: "QuickSight" },
			{ value: "Amazon Redshift" }
		]
	},
	{
		label: "Application Integration",
		options: [
			{ value: "Amazon AppFlow" },
			{ value: "Amazon EventBridge" },
			{ value: "Managed Apache Airflow" },
			{ value: "Amazon MQ" },
			{ value: "Simple Notification Service" },
			{ value: "Simple Queue Service" },
			{ value: "Step Functions" },
			{ value: "SWF" }
		]
	},
	{
		label: "AWS Cost Management",
		options: [
			{ value: "AWS Application Cost Profiler" },
			{ value: "AWS Billing Conductor" },
			{ value: "AWS Budgets" },
			{ value: "AWS Cost Explorer" },
			{ value: "AWS Marketplace Subscriptions" }
		]
	},
	{
		label: "Blockchain",
		options: [
			{ value: "Amazon Managed Blockchain" }
		]
	},
	{
		label: "Business Applications",
		options: [
			{ value: "Alexa for Business" },
			{ value: "Amazon Chime" },
			{ value: "Amazon Chime SDK" },
			{ value: "Amazon Connect" },
			{ value: "Amazon Honeycode" },
			{ value: "Amazon Pinpoint" },
			{ value: "Amazon Simple Email Service" },
			{ value: "AWS Supply Chain" },
			{ value: "AWS Wikr" },
			{ value: "Amazon Workdocs" },
			{ value: "Amazon WorkMail" }
		]
	},
	{
		label: "Compute",
		options: [
			{ value: "AWS App Runner" },
			{ value: "Batch" },
			{ value: "EC2" },
			{ value: "EC2 Image Builder" },
			{ value: "Elastic Beanstalk" },
			{ value: "Lambda" },
			{ value: "Lightsail" },
			{ value: "AWS Outposts" },
			{ value: "Serverless Application Repository" },
			{ value: "AWS SimSpace Weaver" }
		]
	},
	{
		label: "Containers",
		options: [
			{ value: "Elastic Container Registry" },
			{ value: "Elastic Container Service" },
			{ value: "Elastic Kubernetes Service" },
			{ value: "Red Hat OpenShift Service on AWS" }
		]
	},
	{
		label: "Customer Enablement",
		options: [
			{ value: "Activate for Startups" },
			{ value: "AWS IQ" },
			{ value: "Managed Services" },
			{ value: "Support" }
		]
	},
	{
		label: "Database",
		options: [
			{ value: "Amazon DocumentDB" },
			{ value: "DynamoDB" },
			{ value: "ElastiCache" },
			{ value: "Amazon Keyspaces" },
			{ value: "Amazon MemoryDB for Redis" },
			{ value: "Neptune" },
			{ value: "Amazon QLDB" },
			{ value: "RDS" },
			{ value: "Amazon Timestream" }
		]
	},
	{
		label: "Developer Tools",
		options: [
			{ value: "AWS AppConfig" },
			{ value: "Application Composer" },
			{ value: "Cloud9" },
			{ value: "CloudShell" },
			{ value: "CodeArtifact" },
			{ value: "CodeBuild" },
			{ value: "Amazon CodeCatalyst" },
			{ value: "CodeCommit" },
			{ value: "CodeDeploy" },
			{ value: "CodePipeline" },
			{ value: "CodeStar" },
			{ value: "Amazon CodeWhisperer" },
			{ value: "AWS FIS" },
			{ value: "X-Ray" }
		]
	},
	{
		label: "End User Computing",
		options: [
			{ value: "AppStream 2.0" },
			{ value: "WorkSpaces" },
			{ value: "Workspaces Web" }
		]
	},
	{
		label: "Front-end Web & Mobile",
		options: [
			{ value: "AWS Amplify" },
			{ value: "AWS AppSync" },
			{ value: "Device Farm" },
			{ value: "Amazon Location Service" }
		]
	},
	{
		label: "Game Development",
		options: [
			{ value: "Amazon GameLift" },
			{ value: "Amazon GameSparks" }
		]
	},
	{
		label: "Internet of Things",
		options: [
			{ value: "FreeRTOS" },
			{ value: "IoT 1-Click" },
			{ value: "IoT Analytics" },
			{ value: "IoT Core" },
			{ value: "IoT Device Defender" },
			{ value: "IoT Device Management" },
			{ value: "IoT Events" },
			{ value: "AWS IoT FleetWise" },
			{ value: "IoT Greengrass" },
			{ value: "IoT RoboRunner" },
			{ value: "IoT SiteWise" },
			{ value: "IoT TwinMaker" }
		]
	},
	{
		label: "Machine Learning",
		options: [
			{ value: "Amazon Augmented AI" },
			{ value: "Amazon Bedrock" },
			{ value: "Amazon CodeGuru" },
			{ value: "Amazon Comprehend" },
			{ value: "Amazon Comprehend Medical" },
			{ value: "AWS DeepComposer" },
			{ value: "AWS DeepLens" },
			{ value: "AWS DeepRacer" },
			{ value: "Amazon DevOps Guru" },
			{ value: "Amazon Forecast" },
			{ value: "Amazon Fraud Detector" },
			{ value: "Amazon HealthLake" },
			{ value: "Amazon Kendra" },
			{ value: "Amazon Lex" },
			{ value: "Amazon Lookout for Equipment" },
			{ value: "Amazon Lookout for Metrics" },
			{ value: "Amazon Lookout for Vision" },
			{ value: "Amazon Monitron" },
			{ value: "Amazon Omics" },
			{ value: "AWS Panorama" },
			{ value: "Amazon Personalize" },
			{ value: "Amazon Polly" },
			{ value: "Amazon Rekognition" },
			{ value: "Amazon SageMaker" },
			{ value: "Amazon Textract" },
			{ value: "Amazon Transcribe" },
			{ value: "Amazon Translate" }
		]
	},
	{
		label: "Management & Governance",
		options: [
			{ value: "AWS Auto Scaling" },
			{ value: "CDK" },
			{ value: "AWS Chatbot" },
			{ value: "CloudFormation" },
			{ value: "CloudTrail" },
			{ value: "CloudWatch" },
			{ value: "AWS Compute Optimizer" },
			{ value: "Config" },
			{ value: "Control Tower" },
			{ value: "Amazon Grafana" },
			{ value: "Incident Manager" },
			{ value: "Launch Wizard" },
			{ value: "AWS License Manager" },
			{ value: "OpsWorks" },
			{ value: "AWS Organizations" },
			{ value: "Amazon Prometheus" },
			{ value: "AWS Proton" },
			{ value: "AWS Resilience Hub" },
			{ value: "AWS Reource Explorer" },
			{ value: "Resource Groups & Tag Editor" },
			{ value: "Service Catalog" },
			{ value: "Systems Manager" },
			{ value: "AWS Telco Network Builder" },
			{ value: "Trusted Advisor" },
			{ value: "AWS User Notifications" },
			{ value: "AWS Well-Architected Tool" },
			{ value: "AWS Health Dashboard" }
		]
	},
	{
		label: "Media Services",
		options: [
			{ value: "Elastic Transcoder" },
			{ value: "Elemental Appliances & Software" },
			{ value: "Amazon Interactive Video Service" },
			{ value: "Kinesis Video Streams" },
			{ value: "MediaConnect" },
			{ value: "MediaConvert" },
			{ value: "MediaLive" },
			{ value: "MediaPackage" },
			{ value: "MediaStore" },
			{ value: "MediaTailor" },
			{ value: "Nimble Studio" }
		]
	},
	{
		label: "Migration & Transfer",
		options: [
			{ value: "Application Discovery Service" },
			{ value: "AWS Application Migration Service" },
			{ value: "Database Migration Service" },
			{ value: "DataSync" },
			{ value: "AWS Mainframe Modernization" },
			{ value: "AWS Migration Hub" },
			{ value: "AWS Snow Family" },
			{ value: "AWS Transfer Family" }
		]
	},
	{
		label: "Networking & Content Delivery",
		options: [
			{ value: "API Gateway" },
			{ value: "AWS App Mesh" },
			{ value: "AWS Cloud Map" },
			{ value: "CloudFront" },
			{ value: "Direct Connect" },
			{ value: "Global Accelerator" },
			{ value: "AWS Private 5G" },
			{ value: "Route 53" },
			{ value: "Route 53 Application Recover Controller" },
			{ value: "VPC" }
		]
	},
	{
		label: "Quantum Technologies",
		options: [
			{ value: "Amazon Braket" }
		]
	},
	{
		label: "Robotics",
		options: [
			{ value: "AWS RoboMaker" }
		]
	},
	{
		label: "Satellite",
		options: [
			{ value: "Ground Station" }
		]
	},
	{
		label: "Security, Identity, & Compliance",
		options: [
			{ value: "AWS Artifact" },
			{ value: "AWS Audit Manager" },
			{ value: "Certificate Manager" },
			{ value: "CloudHSM" },
			{ value: "Cognito" },
			{ value: "Detective" },
			{ value: "Directory Service" },
			{ value: "AWS Firewall Manager" },
			{ value: "GuardDuty" },
			{ value: "IAM" },
			{ value: "IAM Identity Center" },
			{ value: "Amazon Inspector" },
			{ value: "Key Management Service" },
			{ value: "Amazon Macie" },
			{ value: "AWS Private Certificate Authority" },
			{ value: "Resource Access Manager" },
			{ value: "Secrets Manager" },
			{ value: "Security Hub" },
			{ value: "Security Lake" },
			{ value: "AWS Signer" },
			{ value: "Amazon Verified Permissions" },
			{ value: "WAF & Shield" }
		]
	},
	{
		label: "Storage",
		options: [
			{ value: "AWS Backup" },
			{ value: "EFS" },
			{ value: "AWS Elastic Disaster Recovery" },
			{ value: "FSx" },
			{ value: "S3" },
			{ value: "S3 Glacier" },
			{ value: "Storage Gateway" }
		]
	}
];

export { areas, categories, categoryTypes, teams, statuses, levels, users };
