import {
    Box,
    BreadcrumbGroup,
    Container,
    Header,
    SpaceBetween,
    Table,
    Icon,
    Tabs
  }
  from "@awsui/components-react";
  import { useState, useEffect, useRef } from "react";
  import { useParams } from "react-router-dom";
  import { Link } from "react-router-dom";
  import { users } from "./Enumerations";
  import { getDashboardURL, getItems } from "./API";
  import { getUser } from "./API";
  import DirectReportsTable from "./DirectReportsTable";
  import QuickSightEmbedding from "amazon-quicksight-embedding-sdk"
  import Iframe from 'react-iframe'
  import Dashboard from "./Dashboard"

  
  // eslint-disable-next-line
  export default () => {
    const { id } = useParams();
    const [name, setName] = useState(id);
    const [error, setError] = useState(null);
    let dev = true;

    

    async function getUserName(alias) {
      let user = await getUser(alias)
      let name = alias
    
      if (user.Item !== undefined) {
        name = user.Item.name
      }
      setName(name)
    }
    getUserName(id)

   let getURL = async () => {
      
      if(dev) {return;}
      await getDashboardURL().then(
        (result) => {
          if (result && result.errorMessage) {
            setError(result.errorMessage);
          }
          else {
            console.log("dashboard url " + result.EmbedUrl)
            let url = result.EmbedUrl
            let options = {
              url: url,
              container: '',
              parameters: {
                  country: "United States"
              },
              scrolling: "no",
              height: "800px",
              width: "912px",
              footerPaddingEnabled: true
          }
            const dashboard = QuickSightEmbedding.embedDashboard(options);
          }
        },
        (error) => {
          console.log(error);
          setError(JSON.stringify(error));
  
        }
      );
    }
    let ref = useRef(null);

    useEffect(() => {
      //let containerDiv = document.getElementById("dashboardContainer");
      let containerDiv = ref.current;
      console.log("container Div")
      console.log(containerDiv)
    }, [])
    
    
    return (
        <SpaceBetween directio="vertical" size="l">

          <Box
              margin={{bottom: "m"}}
          >
              <BreadcrumbGroup
              items={[
                  { 
                  text: "Biblio",
                  href: "/"
                  },
                  { 
                  text: "Manager View",
                  href: "/manager/" + id
                  },
                  { 
                  text: id,
                  href: "/manager/" + id
                  },
              ]}
              />
          </Box>

        <Container>
          <SpaceBetween direction="horizontal" size="m">
            <img alt={id||"none"} float="left" height="128px" style={{border: "1px solid #aaa"}} src={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid="+(id||"none")}/>
            <Header description={id} >
              <a target="_blank" rel="noopener noreferrer" href={"https://phonetool.amazon.com/users/" + id} style={{color:'#37475A'}}>
                {name +" "}
                <Icon name="external"/>
              </a>
            
            </Header>
          </SpaceBetween>
        </Container>

        <Tabs
            tabs={[
              {
                label: "Direct Reports",
                id: "reports",
                content: <DirectReportsTable mgrAlias={id}/>
              },
              {
                label: "Quicksight",
                id: "quicksight-tab",
                content: <Dashboard />
                        
              }
            ]}
          />
      </SpaceBetween>
  
    );
}
